.header {
  position: relative;
}
.header__logo {
  position: absolute;
  top: 50px;
  left: 8%;
  height: 42px;
  z-index: 2;
}
.header__banner {
  position: relative;
  z-index: 1;
  background: linear-gradient(to right, #e5f1ff, #f3f7fc);
  text-align: center;

  .header__info {
    position: absolute;
    top: 35%;
    left: 8%;
    width: 609px;
    padding: 35px 42px;
    background: #0a78f0;
    color: #ffffff;
    text-align: left;

    h2 {
      line-height: 39px;
      font-weight: 600;
      font-size: 28px;
    }

    p {
      line-height: 32px;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .banner {
    min-width: 100%;
    object-fit: cover;
    min-height: 609px;
    user-select: none;
    display: block;
  }
}

.wrapper {
  padding-top: 65px;
  padding-bottom: 65px;
}
.wrapper__inner {
  margin: 0 auto;
  width: 100%;
  max-width: 1004px;
  .item {
    position: relative;
    margin-top: 92px;
  }
  .leftImageWrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .image {
      object-fit: cover;
    }
    .item__inner {
      width: 502px;
      height: 266px;
      padding-top: 32px;
      padding-left: 54px;
      margin-top: 32px;
      background: linear-gradient(
        180deg,
        #f3faff 0%,
        rgba(243, 250, 255, 0) 100%
      );
      border-image: linear-gradient(
          180deg,
          rgba(208.73894155025482, 233.64874809980392, 255, 1),
          rgba(209.00000274181366, 234.00000125169754, 255, 0)
        )
        1 1;

      h2 {
        line-height: 84px;
        font-size: 60px;
        font-weight: 600;
        margin: 0;
      }
      p {
        font-size: 24px;
        line-height: 34px;
        margin: 0;
      }
      .blue,
      h2 {
        color: #0a78f0;
      }
    }
  }
}
.about {
  width: 340px;
  vertical-align: middle;
}
.about1 {
  width: 248px;
  vertical-align: middle;
}
.white_wrapper {

  h2 {
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    color: #191919;
    line-height: 45px;
    margin: 0;
    margin-bottom: 50px;
  }
  .images_list {
    background-image: url("../public/assets/images/white_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 95px 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 16px;

    .images_item {
      width: 227px;
      height: 227px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #ffffff;
      border: 1px solid #ffffff;

      .image {
        vertical-align: middle;
        object-fit: contain;
        margin-bottom: 27px;
      }

      h3 {
        font-weight: 500;
        font-size: 18px;
        color: #191919;
        line-height: 25px;
        margin: 0;
      }
    }
  }
}
.footer {
  background-color: #353747;
  padding-top: 17px;
  padding-bottom: 17px;
  text-align: center;

  h3 {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    color: #969799;
    line-height: 20px;
  }

  p {
    margin: 6px 0 0;
  }

  .link_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
  }

  .link_ml {
    margin-left: 20px;
  }

  .link_item {
    font-weight: 400;
    font-size: 14px;
    color: #969799;
    line-height: 20px;
    text-decoration: none;

    &:hover {
      color: #1971ff;
    }
  }
}
